import store from 'config/configureStore';
import { SET_SELECTED_EVIDENCE } from 'reducers/EvidenceReducer';
import { getDataOfDictionaryOptions, getDescriptionofCode } from 'utils/functions';

export const prepareEvidencePartyPersonData = (data, isJson = true) => {
  if (!data || !data.EvidenceParties) return [];

  let { EvidenceParties } = data;
  const { codePartyRelationship } = store.store.getState().dictionary;

  EvidenceParties = isJson ? JSON.parse(EvidenceParties) : EvidenceParties;

  if (!EvidenceParties || !EvidenceParties.length) return [];
  try {
    return EvidenceParties.map((item) => {
      if (item.Relationship) {
        item.PartyType = item.Relationship;
        item.PartyTypeDescription = item.partyTypeDescription;
      }

      if (isJson) {
        if (item?.Addresses) item.PartiesAddresses = item?.Addresses[0];
        if (item?.Employments) item.PartiesEmployment = item?.Employments[0];
        if (item?.PersonImage) {
          // item.PersonImage = arrayBufferToBase64(item?.ppa[0]?.PersonImage);
          item.PersonImage = item?.PersonImage;
        }
        if (item?.sexDescription) {
          item.sexDescription = item?.sexDescription;
        }
        if (item?.raceDescription) {
          item.raceDescription = item?.raceDescription;
        }
        if (item?.ethnicityDescription) {
          item.ethnicityDescription = item?.ethnicityDescription;
        }
        item.ActiveWarrant = item?.Warrant;
      }

      let availableOffenses = [];
      if (item.AvailableOffenses !== null && !Array.isArray(item.AvailableOffenses)) {
        availableOffenses = JSON.parse(item.AvailableOffenses);
      } else availableOffenses = null;

      const person = {
        ptsEvidencePersonId: item.ptsEvidencePersonID,
        ptsPersonId: item.ptsPersonID,
        PersonId: item.PersonID,
        personDetail: {
          ptsEvidencePersonId: item.ptsEvidencePersonID,
          ptsPersonId: item.ptsPersonID,
          PersonId: item.PersonID,
          values: {
            firstName: item.FirstName,
            lastName: item.LastName,
            middleName: item.MiddleName,
            ptsPersonId: item.ptsPersonID,
            age: item.Age,
            ageRangeIncrement: item.AgeRangeIncrement,
            ptsImageId: item.ptsImageID,
            personImage: item.PersonImage,
            birthdate: item.Born ? item.Born.split('T')[0] + 'T00:00:00' : '',
            bloodType: item.BloodType,
            // credentialId: item.,
            ethnicity: item.Ethnicity,
            eyeColor: item.EyeColor,
            hairColor: item.HairColor,
            hairLength: item.HairLength,
            hairStyle: item.HairStyle,
            handed: item.Handed,
            height: item.Height,
            evidenceId: item.ptsEvidenceID,
            partyType: item.PartyType,
            PartyTypeDescription: item.PartyTypeDescription
              ? item.PartyTypeDescription
              : getDescriptionofCode(codePartyRelationship, item.PartyType),
            partyOrder: item.PartyCode?.Order,
            personId: item.PersonID,
            race: item.Race,
            sex: item.Sex,
            sexDescription: item.sexDescription,
            raceDescription: item.raceDescription,
            ethnicityDescription: item.ethnicityDescription,
            weight: item.Weight,
            ssn: item.SSNNumber,
            oln: item.OLNNumber,
            state: item.OLNState,
            suffix: item.Suffix,
            isUnknownParty: item.IsUnknownParty,
            isArrestee: item?.Arrestee ? true : false,
            availableOffenses: availableOffenses,
            isSexOffender: item.SexOffender,
            isSociety: item.IsSociety,
            activeWarrant: item.ActiveWarrant,
            updatedBy: item.UpdatedBy,
            updated: item.Updated,
            createdBy: item.CreatedBy,
            created: item.Created,
            isVictim: item.IsVictim,
            isOffender: item.IsOffender,
            partyClearanceLevel: item.ClearanceLevel,
          },
        },
        contactDetail: {
          ptsAddressId: item?.PartiesAddresses?.ptsAddressID,
          ptsEvidenceAddressId: item?.PartiesAddresses?.ptsEvidenceAddressID,
          values: {
            evidenceId: item?.PartiesAddresses?.ptsEvidenceID,
            personId: item?.PartiesAddresses?.ptsPersonID,
            cityId: item?.PartiesAddresses?.ptsCityID,
            addressNumberPrefix: item?.PartiesAddresses?.AddressNumberPrefix,
            addressNumber: item?.PartiesAddresses?.AddressNumber,
            addressNumberSuffix: item?.PartiesAddresses?.AddressNumberSuffix,
            preDirection: item?.PartiesAddresses?.PreDirection,
            streetName: item?.PartiesAddresses?.StreetName,
            streetType: item?.PartiesAddresses?.StreetType,
            postDirection: item?.PartiesAddresses?.PostDirection,
            city:
              item?.PartiesAddresses?.City?.CityDescription || item?.PartiesAddresses?.cc
                ? item?.PartiesAddresses?.cc[0]?.City
                : item?.PartiesAddresses?.City,
            state: item?.PartiesAddresses?.State,
            postalCode: item?.PartiesAddresses?.PostalCode,
            postalCodeExtension: item?.PartiesAddresses?.PostalCodeExtension,
            county: item?.PartiesAddresses?.County,
            country: item?.PartiesAddresses?.Country,
            fullAddressText: item?.PartiesAddresses?.FullAddressText,
            textmask1: item.ContactInfo1,
            textmask2: item.ContactInfo2,
            emailmask: item.ContactInfo3,
            textmask3: item.ContactInfo4,
            notes: item?.PartiesAddresses?.Notes,
          },
        },
        employmentDetail: {
          ptsEvidencePartyEmploymentId: item?.PartiesEmployment?.ptsEvidencePartyEmploymentID,
          ptsPlaceId: item?.PartiesEmployment?.ptsPlaceID,
          ptsAddressId: item?.PartiesEmployment?.ptsAddressID,
          values: {
            evidenceId: item?.PartiesEmployment?.ptsEvidenceID,
            personId: item?.PartiesEmployment?.ptsPersonID,
            addressId: item?.PartiesEmployment?.ptsAddressID,
            placeName: item?.PartiesEmployment?.PlaceName,
            cityId: item?.PartiesEmployment?.ptsCityID,
            addressNumberPrefix: item?.PartiesEmployment?.AddressNumberPrefix,
            addressNumber: item?.PartiesEmployment?.AddressNumber,
            addressNumberSuffix: item?.PartiesEmployment?.AddressNumberSuffix,
            preDirection: item?.PartiesEmployment?.PreDirection,
            postDirection: item?.PartiesEmployment?.PostDirection,
            streetName: item?.PartiesEmployment?.StreetName,
            streetType: item?.PartiesEmployment?.StreetType,
            city:
              item?.PartiesEmployment?.City?.CityDescription || item?.PartiesEmployment?.cc
                ? item?.PartiesEmployment?.cc[0]?.City
                : item?.PartiesEmployment?.City,
            state: item?.PartiesEmployment?.State,
            postalCode: item?.PartiesEmployment?.PostalCode,
            postalCodeExtension: item?.PartiesEmployment?.PostalCodeExtension,
            county: item?.PartiesEmployment?.County,
            country: item?.PartiesEmployment?.Country,
            fullAddressText: item?.PartiesEmployment?.FullAddressText,
            textmask1: item?.PartiesEmployment?.ContactInfo1,
            textmask2: item?.PartiesEmployment?.ContactInfo2,
            emailmask: item?.PartiesEmployment?.ContactInfo3,
            notes: item?.PartiesEmployment?.Notes,
          },
        },
        otherDetail: {
          values: {
            evidenceId: item.ptsEvidenceID,
            personId: item?.ptsPersonID,
            addressId: item?.ptsAddressID,
            generalAppearance: item?.GeneralAppearance,
            personNotes: item?.Notes,
          },
        },
      };

      return person;
    });
  } catch (err) {
    console.log(err);
  }
};

export const removeEvidencePartiesFromStore = (ptsEvidencePersonId) => {
  return async (dispatch) => {
    let { selectedEvidence } = store.store.getState().evidence;

    const updatedPartiesList = selectedEvidence.evidenceParties.filter(
      (party) => party.ptsEvidencePersonId != ptsEvidencePersonId
    );

    selectedEvidence.evidenceParties = updatedPartiesList;

    dispatch({
      type: SET_SELECTED_EVIDENCE,
      payload: selectedEvidence,
    });
  };
};

export const removeEvidencePartiesFromDatabase = async (ptsEvidencePersonId) => {
  const wsClient = store.store.getState().websocket.websocket;

  if (!wsClient) return;

  const service = wsClient.service('rms-evidence-party');
  service.timeout = 2000000;

  const removedParty = await service.remove(ptsEvidencePersonId);
};

export const processDataForPropertyToEvidence = (type, values) => {
  switch (type) {
    case 'firearm':
      return {
        description: `${values.description || ''} ${
          getDataOfDictionaryOptions('codeItemMakes', 'Code', 'Description', values.make) || ''
        } ${
          getDataOfDictionaryOptions('codeItemModels', 'Code', 'Description', values.model) || ''
        }`,
        offenses: [],
        make: values.make,
        category: getDataOfDictionaryOptions('codeEvidenceCategory', 'Category', 'Code', 'P'),
        model: values.model,
        condition: values.condition,
        serialNumber: values.serialNumber,
        attachments: values.attachments,
        propertyOwner: values.propertyOwner,
        incIncidentPropertyId: values.incIncidentPropertyId,
        propertyOwnerType: values.propertyOwnerType,
      };

    case 'item':
      return {
        make: values.make,
        model: values.model,
        quantity: values.quantity,
        condition: values.condition,
        serialNumber: values.serialNumber,
        category: getDataOfDictionaryOptions('codeEvidenceCategory', 'Category', 'Code', 'P'),
        description: `${values.description || ''} ${
          getDataOfDictionaryOptions('codeItemMakes', 'Code', 'Description', values.make) || ''
        }`,
        incIncidentPropertyId: values.incIncidentPropertyId,
        offenses: [],
        attachments: values.attachments,
        propertyOwner: values.propertyOwner,

        propertyOwnerType: values.propertyOwnerType,
      };

    case 'jewelry':
      return {
        description: `${values.description || ''} ${
          getDataOfDictionaryOptions('codeItemMakes', 'Code', 'Description', values.make) || ''
        } ${
          getDataOfDictionaryOptions('codeItemModels', 'Code', 'Description', values.model) || ''
        }`,
        offenses: [],
        make: values.make,
        model: values.model,
        category: getDataOfDictionaryOptions('codeEvidenceCategory', 'Category', 'Code', 'P'),
        condition: values.condition,
        serialNumber: values.serialNumber,
        attachments: values.attachments,
        propertyOwner: values.propertyOwner,
        incIncidentPropertyId: values.incIncidentPropertyId,
        propertyOwnerType: values.propertyOwnerType,
      };

    case 'structure':
      return {
        description: `${values.description || ''} ${values.bedroomQuantity || ''} ${
          getDataOfDictionaryOptions(
            'codeStructureColors',
            'Code',
            'Description',
            values.primaryColor
          ) || ''
        }`,
        category: getDataOfDictionaryOptions('codeEvidenceCategory', 'Category', 'Code', 'P'),
        incIncidentPropertyId: values.incIncidentPropertyId,
        offenses: [],
        attachments: values.attachments,
        propertyOwner: values.propertyOwner,

        propertyOwnerType: values.propertyOwnerType,
      };

    case 'substance':
      return {
        description: `${values.description || ''}`,
        offenses: [],
        category: getDataOfDictionaryOptions('codeEvidenceCategory', 'Category', 'Code', 'P'),
        substanceType: values.substanceType,
        attachments: values.attachments,
        propertyOwner: values.propertyOwner,
        incIncidentPropertyId: values.incIncidentPropertyId,
        propertyOwnerType: values.propertyOwnerType,
      };

    case 'vehicle':
      return {
        description: `${values.description || ''} ${
          getDataOfDictionaryOptions(
            'codeVehicleMakesModels',
            'ModelCode',
            'ModelDescription',
            values.model
          ) || ''
        } ${
          getDataOfDictionaryOptions('codeVehicleBrands', 'Code', 'Description', values.brand) || ''
        }`,
        offenses: [],
        category: getDataOfDictionaryOptions('codeEvidenceCategory', 'Category', 'Code', 'P'),
        vin: values.vin,
        licenseNumber: values.plateNumber,
        make: values.make,
        model: values.model,
        attachments: values.attachments,
        propertyOwner: values.propertyOwner,
        incIncidentPropertyId: values.incIncidentPropertyId,
        propertyOwnerType: values.propertyOwnerType,
      };
    default:
      return {};
  }
};
