/**
 * Getting Agency Settings
 */
import store from '../config/configureStore';
import { searchPlaceAddresses } from './SearchReducer';
import { getService } from './service';

const GET_AGENCY_SETTINGS = 'SETTINGS/GET_AGENCY_SETTINGS';
const GET_AGENCY_ADDRESSES = 'SETTINGS/GET_AGENCY_ADDRESSES';
const defaultState = {
  settings: [],
  agencyAddress: {},
};

// ======================= ACTIONS =======================
export const getAgencySettings = (AgencyID) => {
  const state = store.store.getState();
  const client = state.websocket.websocket;

  return async (dispatch) => {
    try {
      const service = client.service('settings-agency-rms');
      service.timeout = 200000;
      const data = await service.get(AgencyID);
      dispatch({ type: GET_AGENCY_SETTINGS, payload: data });
    } catch (error) {
      console.log('Cannot fetch Agency Settings', error);
    }
  };
};

export const getAgencyOtherSettings = (AgencyID) => {
  const state = store.store.getState();
  const client = state.websocket.websocket;

  return async (dispatch) => {
    try {
      const service = client.service('settings-agency-settings');
      service.timeout = 200000;
      const data = await service.get(AgencyID);
      dispatch({ type: GET_AGENCY_SETTINGS, payload: data });
    } catch (error) {
      console.log('Cannot fetch Agency Settings', error);
    }
  };
};

export const getAgencyAddresses = (AgencyID) => {
  const state = store.store.getState();
  const agencies = state.dictionary.Agencies;
  const cities = state.dictionary.codeCities;

  const agencyData = agencies.find((agency) => agency.AgencyID === AgencyID);

  return async (dispatch) => {
    if (!agencyData) return;
    try {
      const { ptsPlaceID } = agencyData || {};
      if (!ptsPlaceID) return;
      const service = getService('cad-search-place-address');
      const result = await service.find({
        query: {
          ptsPlaceID,
          forAgency: true,
        },
      });

      let agencyAddress = {};
      if (result && result.length > 0) {
        const selected = result.find((item) => item.IsPrimary) ?? result[0];

        const CityDescription =
          cities.find((item) => item.ptsCityID === selected?.ptsCityID)?.CityDescription || '';

        if (selected) {
          agencyAddress = {
            ...selected,
            City: CityDescription,
          };
        }
      }

      dispatch({ type: GET_AGENCY_ADDRESSES, payload: agencyAddress });
    } catch (error) {
      console.log('Cannot fetch Agency Address', error);
    }
  };
};

export const getJuvenileAge = () => {
  const state = store.store.getState();
  const agencySettings = state.agencySettings.settings;

  return agencySettings.find((ag) => ag.CDRKey === 'Oldest Juvenile').ValueNumeric || 0;
};
export const getDefaultMugshotType = () => {
  const state = store.store.getState();
  const agencySettings = state.agencySettings.settings;
  const mugshotType =
    agencySettings.find((ag) => ag.CDRKey === 'DefaultMugshotType')?.ValueString || '';

  return mugshotType;
};
// ======================= REDUCER =======================
export default function reducer(state = defaultState, action) {
  let time, build, buildTime, appVersion;
  switch (action.type) {
    case GET_AGENCY_SETTINGS:
      return { ...state, settings: [...state.settings, ...action.payload] };
    case GET_AGENCY_ADDRESSES:
      return {
        ...state,
        agencyAddress: action.payload,
      };
    default:
      return state;
  }
}
