import SimpleSchema from 'simpl-schema';

import store from '../../config/configureStore';

import {
  crimeAgainstPropertyCodes,
  gamblingOffense,
  kidnappingOffenses,
  drugNarcoticOffenses,
} from 'simpl-schema-validation/helpers/Codes/UCR';

import { getCategoryOfCode, getDataOfDictionaryOptions } from 'utils/functions';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-187':
        'When the Item Status = Burned, Counterfeited/Forged, Destroyed/Damaged/Vandalized, Recovered, Seized, or Stolen then Item Category NIBRS Property Category, Value must be provided if applicable.',
      'NIBRS-2019.2-188':
        'When the Item Status = Stolen then Item Category NIBRS Property Category, Value, Date Recovered, Stolen Car Quantity, Recovered Car Quantity must be provided if applicable.',
      'NIBRS-2019.2-63':
        'When the Offense Code = 200 and Offense Attempted = "false" then the Item Status must be BURNED.',
      'NIBRS-2019.2-64':
        'When the Offense Code = 250 and Offense Attempted = "false" then the Item Status must be COUNTERFEITED, RECOVERED, or SEIZED.',
      'NIBRS-2019.2-65':
        'When the Offense Code = 290 and Offense Attempted = "false" then the Item Status must be DESTROYED_DAMAGED_VANDALIZED.',
      'NIBRS-2019.2-62':
        'When the Offense Code = 35A or 35B and Offense Attempted = "false" then the Item Status must be NONE or SEIZED.',
      'NIBRS-2019.2-67':
        'When the Offense Code = 39A, 39B, 39C, or 39D and Offense Attempted = "false" then the Item Status must be SEIZED.',
      'NIBRS-2019.2-61':
        'When the Offense Code = 100, 220, or 510 and Offense Attempted = "false" then the Item Status must be NONE, RECOVERED, STOLEN, or UNKNOWN.',
      'NIBRS-2019.2-60':
        'When the Offense Code = 280 and Offense Attempted = "false" then the Item Status must be NONE or RECOVERED.',
      'NIBRS-2019.2-66':
        'When the Offense Code = 120, 210, 23A-23H, 240, 26A-G, 270 and Offense Attempted = "false" then the Item Status must be RECOVERED or STOLEN.',
      'NIBRS-2019.2-71': 'When the Offense = "35B" then you must provide the Property item.',
      'NIBRS-2019.2-73': 'When the Offense = "35A" then you must provide the Substance.',
      'NIBRS-2019.2-90':
        'When the Property Date Recovered is provided, then Item Category NIBRS Property Category and/or Property Value must be provided.',
      'NIBRS-2019.2-68':
        'When the Item Status = Unknown then Item Category NIBRS Property Category, Value, Date Recovered, Stolen Car Quantity, Recovered Car Quantity, Drug Type, Drug quantity, and Drug Measure must be omitted.',
      'NIBRS-2019.2-86':
        'When the Property is 09, 22, 48, 65, 66, or 99, then the property value must be zero.',
      'NIBRS-2019.2-87': 'When the Property is 88, then the property value must be $1.',
      'NIBRS-2019.2-83':
        'When the Offense code indicates 23H=All Other Larceny, then Item Category NIBRS Property Category cannot indicate items that would not fit in pocket or purse (03,05,24,28,37).',
      'NIBRS-2019.2-182':
        'A Property must first be submitted with the Item Status = Stolen before it can be submitted as Recovered. Only exception is when the Offense Code contains 250 or 280 or when the recovered property = 38 (Vehicle Parts/ Accessories).',
      'NIBRS-2019.2-58':
        'When the Offense Code is Kidnapping/Abduction, Crimes against Property, Drug/Narcotic Offenses, or Gambling Offenses then the Item Status is required.',
      'NIBRS-2019.2-59':
        'When the Offense Code is Kidnapping/Abduction, Crimes against Property, Drug/Narcotic Offenses, or Gambling Offenses and Offense Attempted = "true" then the Item Status must be NONE or UNKNOWN.',
      'NIBRS-2019.2-81':
        'When the Offense code indicates 23C=Shoplifting, then Item Category NIBRS Property Category cannot indicate items that would not fit in pocket or purse (01,03,05,12,15,18,24,28-35,37,39).',
      'NIBRS-2019.2-80':
        'When the Offense code indicates 23A=Pocket-picking or 23B=Purse-snatching, then Item Category NIBRS Property Category cannot indicate items that would not fit in pocket or purse (01,03-05,12,15,18,24,28-35,37,39,66,78).',
      'NIBRS-2019.2-79':
        'When the Offense code indicates 220=Burglary/Breaking and Entering, 240=Motor Vehicle Theft, then Item Category NIBRS Property Category cannot indicate Structures types 29 - 35.',
      'NIBRS-2019.2-82':
        'When the Offense code indicates 23D=Theft from Building, 23E=Theft from Coin-Operated Machine/Device, 23F=Theft from Motor Vehicle, and 23G=Theft of Motor Vehicle Parts/Accessories, then Item Category NIBRS Property Category cannot indicate items that would not fit in pocket or purse (03,05,24,28-35,37).',
      'NIBRS-2019.2-77 Property Description':
        'When the Offense code indicates Drug/Narcotic Violations (35A) and the Attempted/Completed is Completed, then you must have a Item Category NIBRS Property Category of 10 = Drugs/Narcotics in the Substance entity.',
      'NIBRS-2019.2-77 Property Loss':
        'When the Offense code indicates Drug/Narcotic Violations (35A) and the Attempted/Completed is Completed, then you must have a Item Status of "SEIZED".',
      'NIBRS-2019.2-69':
        'When the Item Status = Unknown then Substance Description, Substance Value, Date Recovered, Drug Type, Drug quantity, and Drug Measure must be omitted.',
      'NIBRS-2019.2-195':
        'When the Item Status = Seized, then Drug Type, Substance Value, Date Recovered, Drug quantity, and Drug Measure must be provided unless Substance Type code = X-Over 3 Drug Types, then Drug quantity, and Drug Measure must be omitted.',
      'NIBRS-2019.2-57 A':
        'When the Offense Code is Kidnapping/Abduction, Crimes against Property, Drug/Narcotic Offenses, or Gambling Offenses then the Property item is required.',
      'NIBRS-2019.2-57 B':
        'When the Offense Code is not Kidnapping/Abduction, Crimes against Property, Drug/Narcotic Offenses, or Gambling Offenses then the Property item must be omitted.',
      'NIBRS-2019.2-76':
        'When the Offense code is Vehicle Theft (240) and the Item Quantity is more than 0, then the Item Category NIBRS Property Category must be in (03, 05, 24, 28, 37)',
      'NIBRS-2019.2-98': 'The Substance Type code must be unique within the Item Status.',
      'NIBRS-2019.2-74':
        'A maximum of ten Property Segments can be submitted for each type of property loss, recovery, seizure, etc., that occurred in the incident.',
      'NIBRS-2019.2-94': 'Item Quantity must be 01-99 or 00 = Unknown.',
    },
  },
});

export const incPropertiesSchema = new SimpleSchema({
  allStatus: {
    type: Array,
    optional: true,
    custom() {
      // NIBRS-2019.2-74: Property segment
      let countStatus = new Map();
      this.value.forEach((status) => {
        countStatus.set(status, countStatus.has(status) ? countStatus.get(status) + 1 : 1);
      });

      let errorFlag = false;
      for (let key of countStatus.keys()) {
        if (countStatus.get(key) > 10 && key !== '0') {
          // errorFlag = true;
        }
      }

      if (errorFlag) {
        return 'NIBRS-2019.2-74';
      }
    },
  },

  'allStatus.$': {
    type: String,
    optional: true,
    blackbox: true,
    custom() {},
  },

  propertyItem: {
    type: String,
    label: 'NIBRS-2019.2-71: Property Lose Type',
    optional: true,
    custom() {
      let foundProperty = this.obj.incProperties.length > 0 ? true : false;

      if (this.obj.offense.values.statuteDetails?.FBICode === '35B') {
        if (!foundProperty) {
          return 'NIBRS-2019.2-71';
        }
      }

      //NIBRS-2019.2-57: Report Contents Validation
      if (
        kidnappingOffenses.includes(this.obj.offense?.values?.statuteDetails?.FBICode) ||
        gamblingOffense.includes(this.obj.offense?.values?.statuteDetails?.FBICode) ||
        crimeAgainstPropertyCodes.includes(this.obj.offense?.values?.statuteDetails?.FBICode) ||
        drugNarcoticOffenses.includes(this.obj.offense?.values?.statuteDetails?.FBICode)
      ) {
        if (!foundProperty) {
          return 'NIBRS-2019.2-57 A';
        }
      } else {
        if (foundProperty) {
          return 'NIBRS-2019.2-57 B';
        }
      }
    },
  },

  propertySubstance: {
    type: String,
    label: 'NIBRS-2019.2-73: Property Lose Type',
    optional: true,
    custom() {
      if (this.obj.offense.values.statuteDetails?.FBICode === '35A') {
        let foundItem = this.obj.incProperties.find(
          (ip) => ip.incPropertyDetails.values.parentType?.toLowerCase() === 'substance'
        );
        if (!foundItem) {
          return 'NIBRS-2019.2-73';
        }
      }
    },
  },

  type: {
    type: String,
    label: 'Property Type',
    optional: true,
    custom() {
      const codePropertyCategory = store.store.getState().dictionary.codePropertyCategory;
      const propertyType = this.value
        ? getCategoryOfCode(codePropertyCategory, this.value)
        : this.value;

      if (
        this.obj.offense.values.statuteDetails?.FBICode === '23A' ||
        this.obj.offense.values.statuteDetails?.FBICode === '23B'
      ) {
        if (
          [
            '01',
            '03',
            '04',
            '05',
            '12',
            '15',
            '18',
            '24',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '37',
            '39',
            '66',
            '78',
          ].includes(propertyType)
        ) {
          return 'NIBRS-2019.2-80';
        }
      }

      if (this.obj.offense.values.statuteDetails?.FBICode === '23C') {
        if (
          [
            '01',
            '03',
            '05',
            '12',
            '15',
            '18',
            '24',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '37',
            '39',
          ].includes(propertyType)
        ) {
          return 'NIBRS-2019.2-81';
        }
      }

      if (this.obj.offense.values.statuteDetails?.FBICode === '23H') {
        if (['03', '05', '25', '28', '37'].includes(propertyType)) {
          return 'NIBRS-2019.2-83';
        }
      }

      // NIBRS-2019.2-79: Property Description
      if (['220', '240'].includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (['29', '30', '31', '32', '33', '34', '35'].includes(propertyType)) {
          return 'NIBRS-2019.2-79';
        }
      }

      // NIBRS-2019.2-82: Property Description
      if (['23D', '23E', '23F', '23G'].includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (
          ['03', '05', '24', '28', '29', '30', '31', '32', '33', '34', '35', '37'].includes(
            propertyType
          )
        ) {
          return 'NIBRS-2019.2-82';
        }
      }

      // NIBRS-2019.2-77: Property Description
      if (
        this.obj.offense.values.statuteDetails?.FBICode === '35A' &&
        this.obj.offense.values.offenseAttempted === true
      ) {
        if (propertyType !== '10') {
          return 'NIBRS-2019.2-77 Property Description';
        }
      }

      //NIBRS-2019.2-76: Property Description
      if (
        this.obj.offense?.values?.statuteDetails?.FBICode === '240' &&
        this.obj?.originProperty?.values?.quantity > 0
      ) {
        if (!['03', '05', '24', '28', '37'].includes(propertyType)) {
          return 'NIBRS-2019.2-76';
        }
      }

      if (this.obj.property.values.recoveredDate) {
        if (!this.value || this.value?.length === 0) return 'NIBRS-2019.2-90';
      }
    },
  },

  value: {
    type: SimpleSchema.Integer,
    label: 'NIBRS-2019.2-90: Property Date Recovered',
    optional: true,
    custom() {
      const codePropertyCategory = store.store.getState().dictionary.codePropertyCategory;
      const propertyType = getCategoryOfCode(codePropertyCategory, this.obj.property.values.type);

      if (this.obj.property.values.recoveredDate) {
        if (this.value?.length === 0 || !this.value) return 'NIBRS-2019.2-90';
      }

      if (['09', '22', '48', '65', '66', '99'].includes(propertyType) && this.value !== 0) {
        return 'NIBRS-2019.2-86';
      }

      if (propertyType === '88' && this.value !== 1) {
        return 'NIBRS-2019.2-87';
      }
    },
  },

  status: {
    type: String,
    label: 'Property Lose Type',
    optional: true,
    custom() {
      const codePropertyLossType = store.store.getState().dictionary.codePropertyLossType;
      const propertyStatusCategory = getCategoryOfCode(codePropertyLossType, this.value);
      // NIBRS-2019.2-69: Property Lose Type
      if (propertyStatusCategory === 'unknown') {
        if (this.obj.parentType === 'substance') {
          if (
            this.obj?.originProperty?.values?.quantity ||
            this.obj?.originProperty?.values?.quantityMeasure ||
            this.obj?.originProperty?.values?.substanceType
          ) {
            return 'NIBRS-2019.2-69';
          }
        }
      }

      // NIBRS-2019.2-195: Property Lose Type
      if (propertyStatusCategory === 'seized') {
        if (this.obj.parentType === 'substance') {
          const codeSubstanceCategory = store.store.getState().dictionary.codeSubstanceCategory;

          if (
            getCategoryOfCode(
              codeSubstanceCategory,
              this.obj?.originProperty?.values?.substanceType
            ) === 'x'
          ) {
            if (
              this.obj?.originProperty?.values?.quantity ||
              this.obj?.originProperty?.values?.quantityMeasure
            ) {
              return 'NIBRS-2019.2-195';
            }
          } else if (
            !this.obj?.originProperty?.values?.quantity ||
            !this.obj?.originProperty?.values?.quantityMeasure
          ) {
            return 'NIBRS-2019.2-195';
          }
        }
      }

      if (propertyStatusCategory === 'unknown') {
        if (
          this.obj.property.values.value ||
          this.obj.property.values.stolenVehicles ||
          this.obj.property.values.recoveredVehicles ||
          this.obj.property.values.type ||
          this.obj.property.values.recoveredDate
        )
          return 'NIBRS-2019.2-68';
      }

      if (
        (kidnappingOffenses.includes(this.obj.offense.values.statuteDetails?.FBICode) ||
          gamblingOffense.includes(this.obj.offense.values.statuteDetails?.FBICode) ||
          crimeAgainstPropertyCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)) &&
        this.obj.offense.values.offenseAttempted === true
      ) {
        if (propertyStatusCategory !== 'none' && propertyStatusCategory !== 'unknown') {
          return 'NIBRS-2019.2-59';
        }
      }

      if (
        kidnappingOffenses.includes(this.obj.offense.values.statuteDetails?.FBICode) ||
        gamblingOffense.includes(this.obj.offense.values.statuteDetails?.FBICode) ||
        crimeAgainstPropertyCodes.includes(this.obj.offense.values.statuteDetails?.FBICode) ||
        drugNarcoticOffenses.includes(this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        if (!this.value) {
          return 'NIBRS-2019.2-58';
        }
      }

      if (
        this.obj.offense.values.statuteDetails?.FBICode === '200' &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (propertyStatusCategory !== 'burned') {
          return 'NIBRS-2019.2-63';
        }
      }

      if (
        this.obj.offense.values.statuteDetails?.FBICode === '250' &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (
          propertyStatusCategory !== 'countrfeit' &&
          propertyStatusCategory !== 'recovered' &&
          propertyStatusCategory !== 'seized'
        ) {
          return 'NIBRS-2019.2-64';
        }
      }

      if (
        this.obj.offense.values.statuteDetails?.FBICode === '290' &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (propertyStatusCategory !== 'destroyed') {
          return 'NIBRS-2019.2-65';
        }
      }

      if (
        (this.obj.offense.values.statuteDetails?.FBICode === '35A' ||
          this.obj.offense.values.statuteDetails?.FBICode === '35B') &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (propertyStatusCategory !== 'seized' && propertyStatusCategory !== 'none') {
          return 'NIBRS-2019.2-62';
        }
      }

      if (
        (this.obj.offense.values.statuteDetails?.FBICode === '39A' ||
          this.obj.offense.values.statuteDetails?.FBICode === '39B' ||
          this.obj.offense.values.statuteDetails?.FBICode === '39C' ||
          this.obj.offense.values.statuteDetails?.FBICode === '39D') &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (propertyStatusCategory !== 'seized') {
          return 'NIBRS-2019.2-67';
        }
      }

      if (
        (this.obj.offense.values.statuteDetails?.FBICode === '100' ||
          this.obj.offense.values.statuteDetails?.FBICode === '220' ||
          this.obj.offense.values.statuteDetails?.FBICode === '510') &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (
          propertyStatusCategory !== 'none' &&
          propertyStatusCategory !== 'recovered' &&
          propertyStatusCategory !== 'stolen' &&
          propertyStatusCategory !== 'unknown'
        ) {
          return 'NIBRS-2019.2-61';
        }
      }

      if (
        this.obj.offense.values.statuteDetails?.FBICode === '280' &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (propertyStatusCategory !== 'none' && propertyStatusCategory !== 'recovered') {
          return 'NIBRS-2019.2-60';
        }
      }

      //TODO: Improve the logic
      if (
        (this.obj.offense.values.statuteDetails?.FBICode === '120' ||
          this.obj.offense.values.statuteDetails?.FBICode === '210' ||
          this.obj.offense.values.statuteDetails?.FBICode === '240' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23A' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23B' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23C' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23D' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23E' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23F' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23G' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23H' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26A' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26B' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26C' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26D' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26E' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26F' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26G' ||
          this.obj.offense.values.statuteDetails?.FBICode === '270') &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (propertyStatusCategory !== 'stolen' && propertyStatusCategory !== 'recovered') {
          return 'NIBRS-2019.2-66';
        }
      }

      //NIBRS-2019.2-182: Property Recovered/Stolen Check.
      //TODO: Improve the logic
      if (
        propertyStatusCategory === 'recovered' &&
        this.obj.incProperty?.incPropertyDetails.values.status !== 'STOLEN'
      ) {
        if (
          this.obj.offense.values.statuteDetails?.FBICode !== '250' &&
          this.obj.offense.values.statuteDetails?.FBICode !== '280' &&
          this.obj.property.values.type !== '38'
        ) {
          return 'NIBRS-2019.2-182';
        }
      }

      //Make the logic more organized
      if (
        propertyStatusCategory === 'burned' ||
        propertyStatusCategory === 'countrfeit' ||
        propertyStatusCategory === 'recovered' ||
        propertyStatusCategory === 'seized' ||
        propertyStatusCategory === 'destroyed'
      ) {
        if (this.obj.parentType === 'vehicle') {
          if (
            !this.obj.property.values.value ||
            !this.obj.property.values.type
            //  ||
            // !this.obj.property.values.stolenVehicles ||
            // !this.obj.property.values.recoveredVehicles
          ) {
            return 'NIBRS-2019.2-187';
          }
        } else if (this.obj.parentType !== 'vehicle') {
          if (!this.obj.property.values.value || !this.obj.property.values.type) {
            return 'NIBRS-2019.2-187';
          }
        }

        if (propertyStatusCategory === 'recovered') {
          if (!this.obj.property.values.recoveredDate) {
            return 'NIBRS-2019.2-187';
          }
        }
      } else if (propertyStatusCategory === 'stolen') {
        if (this.obj.parentType === 'vehicle') {
          if (
            (!this.obj.property.values.value && this.obj.property.values.value !== 0) ||
            !this.obj.property.values.type ||
            (!this.obj.property.values.stolenVehicles &&
              ['3', '24', '28', '37'].includes(
                getDataOfDictionaryOptions(
                  'codePropertyCategory',
                  'Code',
                  'Category',
                  this.obj.property.values.type
                )
              )) ||
            (!this.obj.property.values.recoveredDate &&
              this.obj.property.values.recoveredFromOtherJurisdiction === true) ||
            (!this.obj.property.values.recoveredVehicles &&
              (this.obj.property.values.recoveredDate || this.obj.property.values.recoveredValue))
          ) {
            return 'NIBRS-2019.2-188';
          }
        } else if (this.obj.parentType !== 'vehicle') {
          if (
            (!this.obj.property.values.value && this.obj.property.values.value !== 0) ||
            !this.obj.property.values.type
          ) {
            return 'NIBRS-2019.2-188';
          }
        }
      }

      // NIBRS-2019.2-77: Property Description
      if (
        this.obj.offense.values.statuteDetails?.FBICode === '35A' &&
        this.obj.offense.values.offenseAttempted === true
      ) {
        if (propertyStatusCategory !== 'seized') {
          return 'NIBRS-2019.2-77 Property Loss';
        }
      }
    },
  },

  propertyDrugType: {
    type: String,
    optional: true,
    custom() {
      //NIBRS-2019.2-98: Property Drug Type

      let statusWithType = new Set();
      for (let incProperty of this.obj.incProperties) {
        const property = this.obj.properties?.find(
          (p) =>
            p.propertyDetails.incIncidentPropertyId ===
            incProperty.incPropertyDetails.incIncidentPropertyId
        );

        const lossType = incProperty.incPropertyDetails?.values?.status;
        const substanceType = property?.propertyDetails?.values?.substanceType;
        if (!substanceType || !lossType) continue;

        const key = `${substanceType} - ${lossType}`;
        if (statusWithType.has(key)) return 'NIBRS-2019.2-98';
        statusWithType.add(key);
      }
    },
  },

  stolenVehicles: {
    type: SimpleSchema.oneOf(SimpleSchema.Integer, String),
    optional: true,
    custom() {
      //NIBRS-2019.2-94: Property Stolen Motor Vehicles
      const stolenVehicles = parseInt(this.value);

      if (this.obj.parentType === 'item') {
        if (stolenVehicles && (stolenVehicles < 0 || stolenVehicles > 99)) {
          return 'NIBRS-2019.2-94';
        }
      }
    },
  },
});
