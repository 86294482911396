/* eslint-disable default-case */
/* eslint-disable prettier/prettier */
import { getDataOfDictionaryOptions } from 'utils/functions';
import store from '../config/configureStore';
import { formatSaveDate, formatSaveDateFrom, formatSaveDateTo } from './TimeReducer';
import { prepareEvidencePartyPersonData } from './helpers/evidenceHelpers';

export const SET_ACTIVE_EVIDENCES = 'SET_ACTIVE_EVIDENCES';
export const SET_TOTAL_ACTIVE_EVIDENCES = 'SET_TOTAL_ACTIVE_EVIDENCES';
export const SET_EVIDENCE_SUBMITTED_FROM_DATE = 'SET_EVIDENCE_SUBMITTED_FROM_DATE';
export const SET_EVIDENCE_SUBMITTED_TO_DATE = 'SET_EVIDENCE_SUBMITTED_TO_DATE';
export const SET_EVIDENCE_QUICK_FILTER = 'SET_EVIDENCE_QUICK_FILTER';
export const SET_EVIDENCE_CURRENT_VIEW = 'SET_EVIDENCE_CURRENT_VIEW';
export const SET_EVIDENCE_FILTER_FORM_VALUES = 'SET_EVIDENCE_FILTER_FORM_VALUES';
export const SET_EVIDENCE_SUBMITTED_BY_VALUE = 'SET_EVIDENCE_SUBMITTED_BY_VALUE';
export const SET_EVIDENCE_REQUIRED_ACTION_FROM_DATE = 'SET_EVIDENCE_REQUIRED_ACTION_FROM_DATE';
export const SET_EVIDENCE_REQUIRED_ACTION_TO_DATE = 'SET_EVIDENCE_REQUIRED_ACTION_TO_DATE';
export const SET_EVIDENCE_REQUIRED_ACTION = 'SET_EVIDENCE_REQUIRED_ACTION';
export const SET_EVIDENCE_CATEGORY_VALUE = 'SET_EVIDENCE_CATEGORY_VALUE';
export const SET_EVIDENCE_TYPE_VALUE = 'SET_EVIDENCE_TYPE_VALUE';
export const SET_EVIDENCE_STATUS_VALUE = 'SET_EVIDENCE_STATUS_VALUE';
export const SET_EVIDENCE_CURRENT_LOCATION = 'SET_EVIDENCE_CURRENT_LOCATION';
export const CLEAR_ALL_EVIDENCE_FILTERS = 'CLEAR_ALL_EVIDENCE_FILTERS';
export const SET_SELECTED_EVIDENCE = 'SET_SELECTED_EVIDENCE';
export const SET_EVIDENCE_PARTIES_PERSON = 'SET_EVIDENCE_PARTIES_PERSON';
export const SET_EVIDENCE_PARTIES_CONTACT = 'SET_EVIDENCE_PARTIES_CONTACT';
export const SET_EVIDENCE_PARTIES_EMPLOYMENT = 'SET_EVIDENCE_PARTIES_EMPLOYMENT';
export const SET_EVIDENCE_PARTIES_OTHERS = 'SET_EVIDENCE_PARTIES_OTHERS';
export const SET_EVIDENCE_AUTO_OPEN_RECORD = 'SET_EVIDENCE_AUTO_OPEN_RECORD';
export const SET_EVIDENCE_CUSTODY_ID_FOR_REPORT = 'SET_EVIDENCE_CUSTODY_ID_FOR_REPORT';
export const getAllActiveEvidence = (page = 0, pageSize = 10) => {
  const client = store.store.getState().websocket.websocket;
  const evidenceFilter = store.store.getState().evidence.evidenceFilters;
  const AgencyID = store.store.getState().user.userAgency;

  return async (dispatch) => {
    try {
      const service = client.service('rms-evidence-v-2');
      service.timeout = 500000;
      service
        .find({
          query: {
            page,
            pageSize,
            SubmittedFrom: evidenceFilter?.submittedFrom
              ? formatSaveDateFrom(evidenceFilter?.submittedFrom)
              : null,
            SubmittedTo: evidenceFilter?.submittedTo
              ? formatSaveDateTo(evidenceFilter?.submittedTo)
              : null,
            SubmittedBy: evidenceFilter?.submittedBy?.FullName || null,
            RequiredActionFrom: evidenceFilter?.requiredActionFrom
              ? formatSaveDateFrom(evidenceFilter?.requiredActionFrom)
              : null,
            RequiredActionTo: evidenceFilter?.requiredActionTo
              ? formatSaveDateTo(evidenceFilter?.requiredActionTo)
              : null,
            RequiredAction: evidenceFilter?.requiredAction || null,
            Category: evidenceFilter?.category || null,
            EvidenceType: evidenceFilter?.evidenceType || null,
            Status: evidenceFilter?.status || null,
            CurrentLocation: evidenceFilter?.currentLocation || null,
            QuickFilter: evidenceFilter?.quickFilter || null,
            IncidentNumber: evidenceFilter?.dialogFilters?.incidentNumber || null,
            ItemNumber: evidenceFilter?.dialogFilters?.itemNumber || null,
            TagNumber: evidenceFilter?.dialogFilters?.tagNumber || null,
            Offense: evidenceFilter?.dialogFilters?.offense || null,
            Make: evidenceFilter?.dialogFilters?.make || null,
            Model: evidenceFilter?.dialogFilters?.model || null,
            VIN: evidenceFilter?.dialogFilters?.serialOrVinNumber || null,
            LicenseNumber: evidenceFilter?.dialogFilters?.licensePlateNumber || null,
            SubstanceType: evidenceFilter?.dialogFilters?.substanceType || null,
            SubstanceClass: evidenceFilter?.dialogFilters?.substanceClass || null,
            DisposalCode: evidenceFilter?.dialogFilters?.disposalCode || null,
            StorageLocation: evidenceFilter?.dialogFilters?.storageLocation || null,
            SubLocation: evidenceFilter?.dialogFilters?.subLocation || null,
            Container: evidenceFilter?.dialogFilters?.container || null,
            CollectedFrom: evidenceFilter?.dialogFilters?.collectedFrom
              ? formatSaveDateFrom(evidenceFilter?.dialogFilters?.collectedFrom)
              : null,
            CollectedTo: evidenceFilter?.dialogFilters?.collectedTo
              ? formatSaveDateTo(evidenceFilter?.dialogFilters?.collectedTo)
              : null,
            CollectedBy: evidenceFilter?.dialogFilters?.collectedBy?.FullName || null,
            DisposalFrom: evidenceFilter?.dialogFilters?.disposalFrom
              ? formatSaveDateFrom(evidenceFilter?.dialogFilters?.disposalFrom)
              : null,
            DisposalTo: evidenceFilter?.dialogFilters?.disposalTo
              ? formatSaveDateTo(evidenceFilter?.dialogFilters?.disposalTo)
              : null,
            FinalDisposition: evidenceFilter?.dialogFilters?.finalDisposition || null,
            AgencyID: AgencyID,
          },
        })
        .then((data) => {
          const { result, rowCount } = data;
          dispatch({ type: SET_TOTAL_ACTIVE_EVIDENCES, payload: rowCount });
          let activeList = [];
          if (result) {
            result.map((r) => {
              if (r.EvidenceCustody) {
                r.EvidenceCustody = JSON.parse(r.EvidenceCustody);
              }
              if (r.EvidenceStorage) {
                r.EvidenceStorage = JSON.parse(r.EvidenceStorage);
              }
              if (r.EvidenceDisposition) {
                r.EvidenceDisposition = JSON.parse(r.EvidenceDisposition);
              }

              if (r.Offenses) {
                r.Offenses = JSON.parse(r.Offenses);
              }

              const IsJSON = true;

              let evidenceParties = prepareEvidencePartyPersonData(r, IsJSON);

              let collectedByFullName = '';

              if (evidenceParties?.length > 0) {
                const collectedBy = evidenceParties.find((ep) => {
                  const partyType = ep?.personDetail?.values?.partyType;

                  return ['COLLECTED', 'SUBMITTED'].includes(
                    getDataOfDictionaryOptions(
                      'codePartyRelationship',
                      'Category',
                      'Code',
                      partyType
                    )
                  );
                });

                if (collectedBy) {
                  collectedByFullName = `${
                    collectedBy?.personDetail?.values?.firstName
                      ? collectedBy?.personDetail.values.firstName
                      : ''
                  } ${
                    collectedBy?.personDetail?.values?.middleName
                      ? collectedBy?.personDetail.values.middleName
                      : ''
                  } ${
                    collectedBy?.personDetail?.values.lastName
                      ? collectedBy?.personDetail.values.lastName
                      : ''
                  }`;
                }
              }

              let displayValuesOnGrid = {
                ptsEvidenceID: r.ptsEvidenceID,
                ptsIncidentID: r.ptsIncidentID,
                Category: r.Category,
                CategoryDescription: r.CategoryDescription,
                CollectedBy: collectedByFullName,
                Container: r.Container,
                ContainerDescription: r.ContainerDescription,
                CurrentLocation: r.CurrentLocation,
                DateCollected: r.DateCollected,
                SubmittedAt: r.SubmittedAt,
                Description: r.Description,
                IncidentNumber: r.IncidentNumber || r.incidentReportID || '',
                ItemNumber: r.ItemNumber,
                Status: r.Status,
                StorageLocation: r.StorageLocation,
                StorageLocationDescription: r.StorageLocationDescription,
                SubLocation: r.SubLocation,
                SubLocationDescription: r.SubLocationDescription,
                EvidenceType: r.EvidenceType,
                SubmittedBy: collectedByFullName,
                RequiredAction: r.RequiredAction,
              };

              let itemRecordDetail = {
                ptsEvidenceId: r.ptsEvidenceID,
                ptsIncidentId: r.ptsIncidentID,
                evidenceId: r.ItemNumber,
                values: {
                  ptsEvidenceId: r.ptsEvidenceID,
                  evidenceType: r.EvidenceType,
                  reportNumber: r.incidentReportID,
                  description: r.Description,
                  requiredAction: r.RequiredAction,
                  actionDate: r.ActionDate,
                  collectedDate: r.DateCollected,
                  submittedAt: r.SubmittedAt,
                  collectedBy: collectedByFullName,
                  submittedBy: collectedByFullName,
                  incidentId: r.ptsIncidentID,
                  IncidentNumber: r.IncidentNumber,
                  evidenceId: r.ItemNumber,
                  category: r.Category,
                  locationCollected: r.LocationCollected,
                  temporaryLocation: r.TemporaryLocation,
                  notes: r.Notes,
                  disposalCode: r.DisposalCode,
                  quantity: r.Quantity,
                  tagNumber: r.TagNumber,
                  make: r.Make,
                  model: r.Model,
                  serialNumber: r.SerialNumber,
                  vin: r.VIN,
                  licenseNumber: r.LicenseNumber,
                  condition: r.Condition,
                  substanceType: r.SubstanceType,
                  substanceClass: r.SubstanceClass,
                  measurement: r.Measurement,
                  propertyOwner: r.PropertyOwner,
                  propertyOwnerType: r.PropertyOwnerType,
                  evidenceClearanceLevel: r?.ClearanceLevel,
                  offenses: r.Offenses,
                  created: formatSaveDate(r.Created),
                  createdBy: r.CreatedBy,
                  updated: formatSaveDate(r.Updated),
                  updatedBy: r.UpdatedBy,
                },
              };

              let evidenceDispositionDetail = {
                ptsEvidenceId: r.ptsEvidenceID,
                ptsIncidentId: r.ptsIncidentID,

                ptsEvidenceDispositionId: r.EvidenceDisposition
                  ? r?.EvidenceDisposition[0]?.ptsEvidenceDispositionID
                  : 0,
                values: {
                  incidentId: r.ptsIncidentID,
                  evidenceId: r.ptsEvidenceID,
                  evidenceDispositionId: r.EvidenceDisposition
                    ? r?.EvidenceDisposition[0]?.ptsEvidenceDispositionID
                    : 0,
                  bypassDisposalAssumption: r.EvidenceDisposition
                    ? r?.EvidenceDisposition[0]?.BypassDispAssumption
                    : '',
                  readyForDisposal: r.EvidenceDisposition
                    ? r?.EvidenceDisposition[0]?.ReadyForDisposal
                    : '',
                  isDisposed: r.EvidenceDisposition ? r?.EvidenceDisposition[0]?.IsDisposed : '',
                  disposedOfBy: r.EvidenceDisposition
                    ? r?.EvidenceDisposition[0]?.DisposedOfBy
                    : '',
                  dispositionDate: r.EvidenceDisposition
                    ? r?.EvidenceDisposition[0]?.DispositionDate
                    : '',
                  collectedDate: r.EvidenceDisposition ? r?.EvidenceDisposition[0]?.Recorded : '',
                  finalDisposition: r.EvidenceDisposition
                    ? r?.EvidenceDisposition[0]?.FinalDisposition
                    : '',
                  dispositionNotes: r.EvidenceDisposition
                    ? r?.EvidenceDisposition[0]?.DispositionNotes
                    : '',

                  returnTo: r.EvidenceDisposition ? r?.EvidenceDisposition[0]?.ReturnTo : '',
                  returnDate: r.EvidenceDisposition ? r?.EvidenceDisposition[0]?.ReturnDate : '',
                  returnBy: r.EvidenceDisposition ? r?.EvidenceDisposition[0]?.ReturnBy : '',
                  howDestroyed: r.EvidenceDisposition
                    ? r?.EvidenceDisposition[0]?.HowDestroyed
                    : '',
                  destructionDate: r.EvidenceDisposition
                    ? r?.EvidenceDisposition[0]?.DestructionDate
                    : '',
                  destructionReturnDate: r.EvidenceDisposition
                    ? r?.EvidenceDisposition[0]?.DestructionReturnDate
                    : '',

                  created: r.EvidenceDisposition
                    ? formatSaveDate(r?.EvidenceDisposition[0]?.Created)
                    : null,
                  createdBy: r.EvidenceDisposition ? r?.EvidenceDisposition[0]?.CreatedBy : null,
                  updated: r.EvidenceDisposition
                    ? formatSaveDate(r?.EvidenceDisposition[0]?.Updated)
                    : null,
                  updatedBy: r.EvidenceDisposition ? r?.EvidenceDisposition[0]?.UpdatedBy : null,
                },
              };

              let evidenceStorageDetails = [];
              r?.EvidenceStorage?.map((s) => {
                let storageDetail = {
                  incEvidenceStorageDetails: {
                    ptsEvidenceId: r.ptsEvidenceID,
                    ptsIncidentId: r.ptsIncidentID,
                    ptsEvidenceStorageId: s?.ptsEvidenceStorageID,
                    values: {
                      incidentId: r.ptsIncidentID,
                      evidenceId: r.ptsEvidenceID,
                      evidenceStorageId: s?.ptsEvidenceStorageID,
                      dateIn: s?.DateTimeIn,
                      storageLocation: s?.StorageLocation,
                      container: s?.Container,
                      subLocation: s?.SubLocation,
                      enteredBy: s?.UpdatedBy,
                      created: formatSaveDate(s?.Created),
                      createdBy: s?.CreatedBy,
                      updated: formatSaveDate(s?.Updated),
                      updatedBy: s?.UpdatedBy,
                    },
                  },
                };
                // setSelectedEvidenceStorage(storageDetail.incEvidenceStorageDetails.ptsEvidenceStorageId);
                evidenceStorageDetails.push(storageDetail);
              });

              let evidenceCustodyDetails = [];
              r?.EvidenceCustody?.map((s) => {
                let custodyDetail = {
                  incEvidenceCustodyDetails: {
                    ptsEvidenceId: r.ptsEvidenceID,
                    ptsIncidentId: r.ptsIncidentID,
                    ptsEvidenceCustodyId: s?.ptsEvidenceCustodyID,
                    values: {
                      incidentId: r.ptsIncidentID,
                      evidenceId: r.ptsEvidenceID,
                      transactionDate: s?.TransactionDate ? s?.TransactionDate : null,
                      transactionType: s?.TransactionType ? s?.TransactionType : '',
                      evidenceCustodyId: s?.ptsEvidenceCustodyID,
                      Status: s?.Status,
                      custodyFrom: s?.CustodyFrom,
                      custodyTo: s?.CustodyTo,
                      reason: s?.Reason,
                      notes: s?.Notes,
                      created: formatSaveDate(s?.Created),
                      createdBy: s?.CreatedBy,
                      updated: formatSaveDate(s?.Updated),
                      updatedBy: s?.UpdatedBy,
                      requiredAction: s?.RequiredAction,
                      disposalCode: s?.DisposalCode,
                      comeFromCheckInFlow: s?.ComeFromCheckInFlow || false,
                    },
                  },
                };
                evidenceCustodyDetails.push(custodyDetail);
              });

              activeList.push({
                ...displayValuesOnGrid,
                itemRecordDetail,
                evidenceParties,
                evidenceDispositionDetail,
                evidenceStorageDetails,
                evidenceCustodyDetails,
              });
            });
          }

          dispatch({ type: SET_ACTIVE_EVIDENCES, payload: activeList });
        });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const updateSelectedEvidence = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_EVIDENCE, payload: state });
  };
};

export const updateEvidencePartiesPersonForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_EVIDENCE_PARTIES_PERSON, payload: state });
  };
};

export const updateEvidencePartiesContactForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_EVIDENCE_PARTIES_CONTACT, payload: state });
  };
};

export const updateEvidencePartiesEmploymentForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_EVIDENCE_PARTIES_EMPLOYMENT, payload: state });
  };
};

export const updateEvidencePartiesOthersForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_EVIDENCE_PARTIES_OTHERS, payload: state });
  };
};

export const setSubmittedFromDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_SUBMITTED_FROM_DATE, payload: date });
  };
};

export const setEvidenceAutoOpenRecord = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_AUTO_OPEN_RECORD, payload });
  };
};

export const setSubmittedToDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_SUBMITTED_TO_DATE, payload: date });
  };
};

export const setSubmittedBy = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_SUBMITTED_BY_VALUE, payload: value });
  };
};

export const setRequiredActionFromDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_REQUIRED_ACTION_FROM_DATE, payload: date });
  };
};

export const setRequiredActionToDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_REQUIRED_ACTION_TO_DATE, payload: date });
  };
};

export const setRequiredAction = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_REQUIRED_ACTION, payload: value });
  };
};

export const setEvidenceCustodyIdForReport = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_CUSTODY_ID_FOR_REPORT, payload: value });
  };
};

export const setCategory = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_CATEGORY_VALUE, payload: value });
  };
};

export const setType = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_TYPE_VALUE, payload: value });
  };
};

export const setStatus = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_STATUS_VALUE, payload: value });
  };
};

export const setCurrentLocation = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_CURRENT_LOCATION, payload: value });
  };
};

export const setQuickFilter = (quickFilter) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_QUICK_FILTER, payload: quickFilter });
  };
};

export const setCurrentView = (currentView) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_CURRENT_VIEW, payload: currentView });
  };
};

export const setEvidenceFilterFormValues = (formValues) => {
  return (dispatch) => {
    dispatch({ type: SET_EVIDENCE_FILTER_FORM_VALUES, payload: formValues });
  };
};

export const clearAllFilters = () => {
  const filterValue = {
    submittedFrom: null,
    submittedTo: null,
    submittedBy: null,
    requiredActionFrom: null,
    requiredActionTo: null,
    requiredAction: '',
    category: '',
    evidenceType: '',
    status: '',
    currentLocation: null,
    quickFilter: '',
    dialogFilters: {
      description: '',
      incidentNumber: '',
      make: '',
      substanceType: '',
      itemNumber: '',
      model: '',
      substanceClass: '',
      tagNumber: '',
      serialOrVinNumber: '',
      offense: '',
      licensePlateNumber: '',
      partyType: '',
      partyName: null,
      disposalCode: '',
      collectedFrom: null,
      collectedTo: null,
      disposalFrom: null,
      disposalTo: null,
      storageLocation: '',
      subLocation: '',
      container: '',
      collectedBy: null,
      finalDisposition: '',
    },
    autoOpenRecord: { isOpen: false, lastFilterItem: null },
  };

  return (dispatch) => {
    dispatch({ type: CLEAR_ALL_EVIDENCE_FILTERS, payload: filterValue });
  };
};

export const initFormModel = {
  isValid: false,
  values: {},
  touched: {},
  errors: {},
  isDirty: false,
  changes: false,
};

export default function reducer(
  state = {
    activeEvidences: null,
    totalActiveEvidences: 0,
    evidenceFilters: {
      submittedFrom: null,
      submittedTo: null,
      submittedBy: null,
      requiredActionFrom: null,
      requiredActionTo: null,
      requiredAction: '',
      currentView: 'evidenceGridView',
      category: '',
      evidenceType: '',
      status: '',
      currentLocation: null,
      quickFilter: '',
      dialogFilters: {
        description: '',
        incidentNumber: '',
        make: '',
        substanceType: '',
        itemNumber: '',
        model: '',
        substanceClass: '',
        tagNumber: '',
        serialOrVinNumber: '',
        offense: '',
        licensePlateNumber: '',
        partyType: '',
        partyName: null,
        disposalCode: '',
        collectedFrom: null,
        collectedTo: null,
        disposalFrom: null,
        disposalTo: null,
        storageLocation: '',
        subLocation: '',
        container: '',
        collectedBy: null,
        finalDisposition: '',
      },
    },
    selectedEvidence: null,
    partiesPersonForm: initFormModel,
    partiesContactForm: initFormModel,
    partiesEmploymentForm: initFormModel,
    partiesOthersForm: initFormModel,
    autoOpenRecord: { isOpen: false, lastFilterItem: null },
    ptsEvidenceCustodyIdForReport: null,
  },
  action
) {
  switch (action.type) {
    case SET_ACTIVE_EVIDENCES:
      // console.log(action.payload, 'here');
      return { ...state, activeEvidences: action.payload };
    case SET_TOTAL_ACTIVE_EVIDENCES:
      return { ...state, totalActiveEvidences: action.payload };
    case SET_SELECTED_EVIDENCE:
      return { ...state, selectedEvidence: action.payload };
    case SET_EVIDENCE_CUSTODY_ID_FOR_REPORT:
      return { ...state, ptsEvidenceCustodyIdForReport: action.payload };
    case SET_EVIDENCE_PARTIES_PERSON:
      return { ...state, partiesPersonForm: action.payload };
    case SET_EVIDENCE_PARTIES_CONTACT:
      return { ...state, partiesContactForm: action.payload };
    case SET_EVIDENCE_AUTO_OPEN_RECORD:
      return { ...state, autoOpenRecord: action.payload };
    case SET_EVIDENCE_PARTIES_EMPLOYMENT:
      return { ...state, partiesEmploymentForm: action.payload };
    case SET_EVIDENCE_PARTIES_OTHERS:
      return { ...state, partiesOthersForm: action.payload };
    case SET_EVIDENCE_SUBMITTED_FROM_DATE:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, submittedFrom: action.payload },
      };
    case SET_EVIDENCE_SUBMITTED_TO_DATE:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, submittedTo: action.payload },
      };
    case SET_EVIDENCE_SUBMITTED_BY_VALUE:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, submittedBy: action.payload },
      };
    case SET_EVIDENCE_REQUIRED_ACTION_FROM_DATE:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, requiredActionFrom: action.payload },
      };
    case SET_EVIDENCE_REQUIRED_ACTION_TO_DATE:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, requiredActionTo: action.payload },
      };
    case SET_EVIDENCE_REQUIRED_ACTION:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, requiredAction: action.payload },
      };
    case SET_EVIDENCE_CATEGORY_VALUE:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, category: action.payload },
      };
    case SET_EVIDENCE_TYPE_VALUE:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, evidenceType: action.payload },
      };
    case SET_EVIDENCE_STATUS_VALUE:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, status: action.payload },
      };
    case SET_EVIDENCE_CURRENT_LOCATION:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, currentLocation: action.payload },
      };
    case SET_EVIDENCE_QUICK_FILTER:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, quickFilter: action.payload },
      };
    case SET_EVIDENCE_CURRENT_VIEW:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, currentView: action.payload },
      };
    case SET_EVIDENCE_FILTER_FORM_VALUES:
      return {
        ...state,
        evidenceFilters: { ...state.evidenceFilters, dialogFilters: { ...action.payload } },
      };
    case CLEAR_ALL_EVIDENCE_FILTERS:
      return {
        ...state,
        evidenceFilters: {
          ...state.evidenceFilters,
          submittedFrom: action.payload.submittedFrom,
          submittedTo: action.payload.submittedTo,
          submittedBy: action.payload.submittedBy,
          requiredActionFrom: action.payload.requiredActionFrom,
          requiredActionTo: action.payload.requiredActionTo,
          requiredAction: action.payload.requiredAction,
          category: action.payload.category,
          evidenceType: action.payload.evidenceType,
          status: action.payload.status,
          currentLocation: action.payload.currentLocation,
          quickFilter: action.payload.quickFilter,
          dialogFilters: action.payload.dialogFilters,
        },
      };
  }

  return state;
}
