import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import {
  closeEditPerson,
  closePersonRelatedRecordsDetail,
  setPersonDialogFlags,
  showEditWarrant,
} from 'reducers/PersonDialogsReducer';
import MainDialog from 'components/RMSDialog';
import { connect, useDispatch, useSelector } from 'react-redux';
import RMSXGrid from 'components/RMSXGrid';
import { genColHeaderName } from 'utils/functions';
import {
  Badge,
  Fab,
  Tooltip,
  makeStyles,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@material-ui/core';
import RMSConfirmationModal from 'components/RMSModals/RMSConfirmationModal';
import IncEventDetails from 'components/RMSForms/RMSIncidentForm/IncEventDetails';
import { Close, Edit, Visibility } from '@material-ui/icons';
import RecordBookingDetails from './RecordBookingDetails';
import { displayDate } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dialogBox: {
    maxWidth: '850px',
    minHeight: '150px',
  },
  filter: {
    minWidth: 548,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

const ErrorBadge = withStyles({
  badge: {
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);
const StyledErrorBadge = withStyles({
  badge: {
    backgroundColor: 'var(--danger)',
    color: 'var(--danger)',
    top: '50%', // Move badge to the middle vertically
    left: '8px',
  },
})(ErrorBadge);

const renderOffenses = (params) => {
  return (
    <span>
      {params?.map((offense) => {
        return offense.Description + ', ';
      })}
    </span>
  );
};

const renderWarrantID = (params) => {
  const { row } = params;

  return (
    <StyledErrorBadge
      overlap="circle"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      variant={row.Disposition === 'Active' ? 'dot' : ''}>
      <div style={{ paddingLeft: '15px' }}>{row.WarrantID}</div>
    </StyledErrorBadge>
  );
};

const cadIncidentColumns = [
  { field: 'EventID', width: 200 },
  { field: 'PersonRelationship', width: 200, headerName: 'Relationship' },
  { field: 'CallType', headerName: 'Event Type', width: 200 },

  {
    field: 'ReceivedDate',
    format: 'date',
    width: 200,
    // sort: 'asc',
  },
  { field: 'Address', headerName: 'Location', width: 200 },
  { field: 'Status', width: 200 },
];

const warrantColumns = [
  {
    field: 'IssueAgencyID',
    headerName: 'Agency',
    width: 200,
    hide: false,
  },
  {
    field: 'WarrantID',
    headerName: 'Warrant ID',
    width: 200,
    hide: false,
    renderCell: renderWarrantID,
  },
  {
    field: 'IssuedDate',
    format: 'date',
    width: 200,
    headerName: 'Issued Date',
    hide: false,
  },
  {
    field: 'StatuteDescription',
    headerName: 'Offense',
    width: 200,
    hide: false,
  },
  {
    field: 'Disposition',
    headerName: 'Disposition',
    width: 200,
    hide: false,
  },
  {
    field: 'ServedByDate',
    format: 'date',
    width: 200,
    headerName: 'Served By Date',
    hide: false,
  },
];

const incidentColumns = [
  {
    field: 'AgencyID',
    headerName: 'Agency',
    width: 150,
    hide: false,
  },
  {
    field: 'IncidentID',
    headerName: 'Incident ID',
    width: 150,
    hide: false,
  },
  {
    field: 'PersonRelationship',
    headerName: 'Relationship',
    width: 150,
    hide: false,
  },
  {
    field: 'CallType',
    headerName: 'Event Type',
    width: 150,
    hide: false,
  },
  {
    field: 'Status',
    headerName: 'Status',
    width: 150,
    hide: false,
  },
  {
    field: 'OccurredDate',
    format: 'date',
    width: 150,
    headerName: 'Occurred Date',
    hide: false,
  },
  {
    field: 'FullAddressText',
    headerName: 'Location',
    width: 200,
    hide: false,
  },
  {
    field: 'Offenses',
    headerName: 'Offenses',
    width: 250,
    hide: false,
    renderCell: (params) => {
      return renderOffenses(params.value);
    },
  },
];

const citationColumns = [
  {
    field: 'AgencyID',
    headerName: 'Agency',
    width: 150,
    hide: false,
  },
  {
    field: 'CitationNumber',
    headerName: 'Citation Number',
    width: 150,
    hide: false,
  },
  {
    field: 'PersonRelationship',
    headerName: 'Relationship',
    width: 150,
    hide: false,
  },
  {
    field: 'CitationDate',
    format: 'date',
    width: 150,
    headerName: 'Citation Date',
    hide: false,
  },
  {
    field: 'Offenses',
    headerName: 'Violation',
    width: 250,
    hide: false,
    renderCell: (params) => {
      return renderOffenses(params.value);
    },
  },
  {
    field: 'Court',
    headerName: 'Court',
    width: 150,
    hide: false,
  },
  {
    field: 'CourtDate',
    format: 'date',
    width: 150,
    headerName: 'Court Date',
    hide: false,
  },
];

const bookingsColumn = [
  {
    field: 'BookingAgencyID',
    headerName: 'Agency',
    width: 150,
    hide: false,
  },
  {
    field: 'BookingID',
    headerName: 'Booking ID',
    width: 150,
    hide: false,
  },
  // {
  //   field: 'BookingType',
  //   headerName: 'Booking Type',
  //   width: 150,
  //   hide: false,
  // },
  {
    field: 'BeginDate',
    format: 'date',
    width: 150,
    headerName: 'Begin Date',
    hide: false,
  },
  {
    field: 'ProjectedReleaseDate',
    format: 'date',
    width: 150,
    headerName: 'Projected Release Date',
    hide: false,
  },
  {
    field: 'ActualReleaseDate',
    format: 'date',
    width: 150,
    headerName: 'Actual Release Date',
    hide: false,
  },
  {
    field: 'HasBookingAdditionalCharges',
    format: 'bool',
    width: 150,
    headerName: 'Additional Charges',
    hide: false,
  },
  // {
  //   field: 'BondAmount',
  //   headerName: 'Bond Amount',
  //   width: 150,
  //   hide: false,
  // },
  // {
  //   field: 'IsBondDenied',
  //   format: 'bool',
  //   width: 150,
  //   headerName: 'Is Bond Denied',
  //   hide: false,
  // },
];

function RelatedRecordDetails(props) {
  const { data, ptsPersonID } = props;
  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEventDialog, setOpenEventDialog] = useState(false);
  const [eventData, setEventData] = useState(null);
  const history = useHistory();
  const permissions = useSelector((state) => state.permissions);
  const [viewPermission, setViewPermission] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setColumns(processCols(data[1][0]));
    setRows(processRows(data[1]));
  }, []);

  useEffect(() => {
    setPermission();
  }, [permissions]);

  const processCols = (row) => {
    if (data[0] === 'CadIncidents') return cadIncidentColumns;
    else if (data[0] === 'RmsIncidents') return incidentColumns;
    else if (data[0] === 'Warrants') return warrantColumns;
    else if (data[0] === 'Citations') return citationColumns;
    else if (data[0] === 'Bookings') return bookingsColumn;
  };

  const processRows = (rows) => {
    return rows.map((row, idx) => {
      row.id = idx;
      if (data[0] === 'Warrants') {
        row.IssuedDate = displayDate(row.IssuedDate);
        row.ServedByDate = displayDate(row.ServedByDate);
      }
      return row;
    });
  };

  const close = () => {
    props.closePersonRelatedRecordsDetail();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const view = () => {
    const item = rows.find((row) => row.id === selection);
    if (data[0] === 'RmsIncidents') setOpenConfirmation(true);
    if (data[0] === 'Warrants') props.showEditWarrant(item);
    if (data[0] === 'Citations') setOpenConfirmation(true);
    if (data[0] === 'CadIncidents') {
      setEventData(item);
      setOpenEventDialog(true);
    }
  };

  const setPermission = () => {
    if (data[0] === 'RmsIncidents') {
      const incidentsPermission = permissions?.rms?.Incidents;
      if (incidentsPermission?.Edit) setViewPermission('Edit');
      else if (incidentsPermission?.Read) setViewPermission('Read');
      else setViewPermission(null);
    } else if (data[0] === 'Warrants') {
      const warrantsPermission = permissions?.globals?.Warrants;
      if (warrantsPermission?.Edit) setViewPermission('Edit');
      else if (warrantsPermission?.Read) setViewPermission('Read');
      else setViewPermission(null);
    } else if (data[0] === 'Citations') {
      const citationsPermission = permissions?.globals?.Citations;
      if (citationsPermission?.Edit) setViewPermission('Edit');
      else if (citationsPermission?.Read) setViewPermission('Read');
      else setViewPermission(null);
    } else if (data[0] === 'CadIncidents') {
      const cadEventPermission = permissions?.cad?.Events;
      if (cadEventPermission?.Edit || cadEventPermission?.Read) setViewPermission('Read');
      else setViewPermission(null);
    } else setViewPermission(null);
  };

  const listActions = () => (
    <div className={classes.actions}>
      {selection !== null && viewPermission && (
        <>
          <Tooltip title={viewPermission + ' ' + data[0]}>
            <Fab size="small" color="secondary" onClick={view} className="ml-2">
              {viewPermission === 'Edit' ? <Edit /> : <Visibility />}
            </Fab>
          </Tooltip>
        </>
      )}
      <Tooltip title="Close">
        <Fab size="small" color="secondary" onClick={close} className="ml-2">
          <Close />
        </Fab>
      </Tooltip>
    </div>
  );

  const HandleModalSave = () => {
    const item = rows.find((row) => row.id === selection);
    if (data[0] === 'RmsIncidents') history.push(`/incidents/edit/${item.ptsIncidentID}`);
    if (data[0] === 'Citations') history.push(`/citations/edit/${item.ptsCitationID}`);
    props.closeEditPerson();
    dispatch(setPersonDialogFlags({ openRelatedRecords: true, ptsPersonID, data }));
    close();
  };

  return (
    <MainDialog fullScreen toolbar onClose={close} title={genColHeaderName(data[0])}>
      <div>
        <div className={classes.filterWrap}>
          <div className={classes.filterWrap}>
            <div className={classes.filter}></div>
            {listActions()}
          </div>
        </div>
        {data[0] === 'Bookings' ? (
          <RecordBookingDetails data={rows} />
        ) : (
          <RMSXGrid
            name={data[0]}
            columns={columns}
            setColumns={setColumns}
            rows={rows}
            // disableSelectionOnClick
            xGridHeight={'84vh'}
            setSelection={setSelection}
          />
        )}
      </div>

      <RMSConfirmationModal
        open={openConfirmation}
        title={'This record is going to closed!'}
        text={'Do you wish to proceed?'}
        saveBtnName="Confirm"
        noBtnName="Cancel"
        closeDialog={() => setOpenConfirmation(false)}
        handleModalSave={() => HandleModalSave()}
      />

      <Dialog
        classes={{
          paperWidthSm: classes.dialogBox,
        }}
        open={openEventDialog}
        onClose={() => setOpenEventDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          className={'d-flex justify-content-between align-items-center'}
          id="alert-dialog-title"
          style={{ background: '#1976d2' }}>
          Event Details
        </DialogTitle>
        <DialogContent>
          <IncEventDetails event={{ Event: eventData }} ptsEventID={eventData?.ptsEventID} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEventDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </MainDialog>
  );
}

export default connect(null, { closePersonRelatedRecordsDetail, showEditWarrant, closeEditPerson })(
  RelatedRecordDetails
);
