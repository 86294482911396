import React from 'react';
import { connect } from 'react-redux';

import AddPersonDialog from './AddPersonDialog';
import ContactMethodDialog from './ContactMethodDialog';
import EditNameDialog from './EditNameDialog';
import EditPersonDialog from './EditPersonDialog';
import AddFeatureDialog from './EditPersonDialog/AddFeatureDialog';
import PersonNameDialog from './EditPersonDialog/PersonNameDialog';
import PeopleCredentialsDialog from './PeopleCredentialsDialog';
import PeopleEmploymentDialog from './PeopleEmploymentDialog';
import PeopleAssociateDialog from './PeopleAssociateDialog';
import WarrantDialog from './WarrantDialog';
import AddPersonPhotoAttachment from './AddPersonPhotoAttachment';
import Address from './Address';
import AddAttachment from './AddAttachment';
import EditAttachment from './EditAttachment';
import AddDnaSampleDialog from './EditPersonDialog/AddDnaSampleDialog';
import EditPersonPhotoDialog from './EditPersonDialog/EditPersonPhotoDialog';
import ReportsDialog from './ReportsDialog';
import VehicleAddEditRegDialog from './EditVehicleDialog/VehicleAddEditRegDialog';
import PlaceEdit from './PlaceEdit';
import DictionaryDialog from './DictionaryDialog';
import RelatedRecordDetails from './EditPersonDialog/RecordDetailsDialog';
import AddVehicleDialog from './AddVehicleDialog';
import EditVehicleDialog from './EditVehicleDialog';
import RMSPersonTagsDialog from './RMSPersonTagsDialog';
import EditPersonAddressDialog from './EditPersonAddressDialog';
import DuplicateAddressDialog from './Address/DuplicateAddressDialog';
import PlaceEmployeeDialog from './PlaceEmployeeDialog';
import EditPlaceAddressDialog from './PlaceEdit/EditPlaceAddressDialog';
import DisplayData from './DisplayData';
import AddMultipleFeatureDialog from './EditPersonDialog/AddMultipleFeatureDialog';
import AddWarrantDialog from './AddWarrantDialog';
import EditWarrantDialog from './EditWarrantDialog';
import WarrantPartiesDialog from './EditWarrantDialog/WarrantPartiesDialog';
import AdditionalOffenseDialog from './EditWarrantDialog/AdditionalOffenseDialog';
import AddBulletin from './AddBulletin';
import EditBulletin from './EditBulletin';
import EditCredentialDialog from './EditCredentialDialog';
import TowingDialog from './TowingDialog';
import EditZones from './EditZones';
import BulletinDetails from './BulletinDetails';
import AddZoneDialog from './AddZoneDialog';

function Dialogs(props) {
  const { isAuthenticated, dialogs } = props;
  const {
    addPerson,
    editPerson,
    addWarrant,
    editWarrant,
    editWarrantParty,
    addPage,
    addOutserviceUnit,
    addEvent,
    editEvent,
    editSop,
    editPageGroup,
    editPageRecipient,
    editPageTemplate,
    dispRecommendation,
    unitProps,
    addNote,
    newUnitStatus,
    unitZone,
    settings,
    displayData,
    sops,
    addBulletin,
    editBulletin,
    address,
    duplicateAddress,
    bulletinDetails,
    report,
    rotations,
    addVehicle,
    editVehicle,
    vehicleEditReg,
    towing,
    about,
    addUnit,
    editUnit,
    dictionary,
    testing,
    addAttachment,
    editAttachment,
    editCredential,
    editPlace,
    editName,
    editContactMethod,
    editPeopleCredentials,
    editPeopleEmployment,
    editPlaceEmployment,
    warrants,
    personDnaSample,
    personFeature,
    personFeatureMultiple,
    personPhotoAttachment,
    editPersonPhoto,
    personName,
    personRelatedRecords,
    editPlaceAddress,
    addEditUnitResource,
    showPersonTagInfo,
    personAssociate,
    editPeopleAddress,
    addAdditionalOffense,
    editZone,
    addZone,
  } = dialogs;

  return (
    <>
      {isAuthenticated && (
        <>
          {addPerson !== null && <AddPersonDialog data={addPerson} />}
          {editPerson !== null && (
            <EditPersonDialog
              ptsPersonID={editPerson.ptsPersonID ? editPerson.ptsPersonID : editPerson}
              origin={editPerson.origin}
            />
          )}

          {addWarrant !== null && <AddWarrantDialog />}
          {editWarrant !== null && <EditWarrantDialog data={editWarrant} />}
          {editWarrantParty !== null && <WarrantPartiesDialog data={editWarrantParty} />}
          {addAdditionalOffense !== null && <AdditionalOffenseDialog data={addAdditionalOffense} />}

          {editName !== null && <EditNameDialog ptsNameID={editName} />}
          {editContactMethod !== null && <ContactMethodDialog data={editContactMethod} />}
          {editPeopleCredentials !== null && (
            <PeopleCredentialsDialog data={editPeopleCredentials} />
          )}
          {editPeopleEmployment !== null && <PeopleEmploymentDialog data={editPeopleEmployment} />}
          {warrants !== null && <WarrantDialog data={warrants} />}
          {personDnaSample != null && <AddDnaSampleDialog data={personDnaSample} />}
          {personFeature != null && <AddFeatureDialog data={personFeature} />}
          {personFeatureMultiple != null && (
            <AddMultipleFeatureDialog data={personFeatureMultiple} />
          )}
          {personPhotoAttachment !== null && (
            <AddPersonPhotoAttachment
              ptsPersonID={personPhotoAttachment.data?.ptsPersonID}
              IsMarkType={personPhotoAttachment.IsMarkType}
              rows={personPhotoAttachment.rows}
            />
          )}
          {editPersonPhoto !== null && (
            <EditPersonPhotoDialog
              data={editPersonPhoto.data}
              IsMarkType={editPersonPhoto.IsMarkType}
              rows={editPersonPhoto?.rows}
            />
          )}
          {personName !== null && <PersonNameDialog data={personName} />}
          {personRelatedRecords !== null && (
            <RelatedRecordDetails
              data={personRelatedRecords.data}
              ptsPersonID={personRelatedRecords.ptsPersonID}
            />
          )}
          {vehicleEditReg !== null && (
            <VehicleAddEditRegDialog vehicleData={vehicleEditReg} type={vehicleEditReg?.type} />
          )}
          {addVehicle !== null && <AddVehicleDialog />}
          {editVehicle !== null && (
            <EditVehicleDialog vehicleData={editVehicle} type={editVehicle.type} />
          )}
          {address !== null && (
            <Address
              ptsAddressID={address.ptsAddressID}
              tab={address.tab}
              eventID={address.EventID}
              copyAddress={address.copyAddress}
            />
          )}
          {duplicateAddress !== null && (
            <DuplicateAddressDialog ptsAddressID={duplicateAddress.ptsAddressID} />
          )}
          {addAttachment !== null && <AddAttachment data={addAttachment} />}
          {editAttachment !== null && <EditAttachment data={editAttachment} />}
          {report !== null && <ReportsDialog report={report} />}
          {dictionary !== null && <DictionaryDialog data={dictionary} />}
          {editPlace !== null && (
            <PlaceEdit
              ptsPlaceID={typeof editPlace === 'object' ? editPlace.ptsPlaceID : editPlace}
              ptsAddressID={typeof editPlace === 'object' ? editPlace.ptsAddressID : null}
            />
          )}
          {editPlaceEmployment !== null && <PlaceEmployeeDialog data={editPlaceEmployment} />}
          {editPlaceAddress !== null && <EditPlaceAddressDialog ptsAddressID={editPlaceAddress} />}
          {showPersonTagInfo !== null && <RMSPersonTagsDialog data={showPersonTagInfo} />}
          {personAssociate !== null && <PeopleAssociateDialog data={personAssociate} />}
          {editPeopleAddress !== null && <EditPersonAddressDialog data={editPeopleAddress} />}
          {displayData !== null && (
            <DisplayData
              data={displayData.data}
              title={displayData.title}
              ptsAlertID={displayData.ptsAlertID}
            />
          )}
          {addBulletin !== null && <AddBulletin data={addBulletin} />}
          {editBulletin !== null && <EditBulletin ptsAlertID={editBulletin} />}
          {editCredential !== null && <EditCredentialDialog data={editCredential} />}
          {towing !== null && <TowingDialog data={towing} />}
          {editZone !== null && <EditZones data={editZone} />}
          {bulletinDetails !== null && <BulletinDetails data={bulletinDetails} />}
          {addZone !== null && <AddZoneDialog />}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    dialogs: state.dialogs,
  };
};

export default connect(mapStateToProps)(Dialogs);
