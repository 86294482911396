import { makeStyles, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';

import { useSelector } from 'react-redux';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';
import { Autocomplete } from '@material-ui/lab';
import DownloadCSV from './DownloadCSV';
import { processParameters } from './reportDataProcessingHelper';
import PersonLookup2 from 'components/RMSPersonLookup/PersonLookup2';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
const useStyles = makeStyles((theme) => ({
  reportContainer: {
    display: 'flex',
  },
  reportSelection: {
    flex: 0.3,
  },
  reportFilter: {
    flex: 0.7,
  },
  report: {
    paddingBottom: '4px',
  },
}));

const CitationReportFilter = (props) => {
  const classes = useStyles();
  const { reportForm, handleReportFormChange, ReportID } = props;
  const formValue = reportForm[ReportID] || {};
  const [demoForm, setDemoForm] = useState({
    values: {},
  });
  const codePartyRelationship = useSelector((state) => state.dictionary.codePartyRelationship);
  const [ptsStatueArray, setPtsStatueArray] = useState([]);
  const agencySettings = useSelector((state) => state.agencySettings.settings);
  const userAgency = useSelector((state) => state.user.userAgency);
  const ptsStatute = useSelector((state) => state.dictionary.ptsStatute);
  const ptsFBICode = useSelector((state) => state.dictionary.ptsFBICode);

  const codeVehicleMakesModels = useSelector((state) => state.dictionary.codeVehicleMakesModels);
  const codeVehicleMakes = useSelector((state) => state.dictionary.codeVehicleMakes);
  const partyTypes = useMemo(() => {
    return codePartyRelationship?.filter(
      (p) => p.Entities !== null && p.Entities.includes('Citation')
    );
  }, codePartyRelationship);

  const legacyExportSetting = agencySettings?.find(
    (item) => item.Path === `${userAgency}.RMS.AllowCitationLegacyExport`
  );
  const isLegacyExportEnable =
    legacyExportSetting && legacyExportSetting?.ValueBoolean === true ? true : false;

  const [filteredVehicleModels, setFilteredVehicleModels] = useState([]);

  useEffect(() => {
    let vehicleModelsArray = codeVehicleMakesModels;
    let vehicleMake = formValue?.make;
    let vehicleModel = formValue?.model;

    if (vehicleMake) {
      vehicleModelsArray = vehicleModelsArray.filter((vm) => vm?.MakeCode === vehicleMake);
    } else if (!vehicleMake && vehicleModel) {
      handleReportFormChange(ReportID, 'model', null);
    }

    setFilteredVehicleModels(vehicleModelsArray);
  }, [formValue.make]);

  useEffect(() => {
    let sortedArray = ptsStatute
      .filter((s) => s.Description !== '')
      .sort((a, b) => (a.Description > b.Description ? 1 : -1));
    let arr = [];

    sortedArray.forEach((s) => {
      let fbCode = ptsFBICode.find((fb) => fb.ptsFBICodeID === s.ptsFBICodeID)?.FBICode;
      s.FBICode = fbCode;
      arr.push(s);
    });
    if (isLegacyExportEnable) {
      arr = arr.filter((item) => item.StatuteID);
    }

    setPtsStatueArray(arr);
  }, []);

  return (
    <div className={classes.report}>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" className="py-2">
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
          <DownloadCSV
            ReportID={ReportID}
            filters={processParameters(ReportID, formValue, 'csv')}
          />
        </Grid>
      </Grid>
      <Grid container justify="left" alignItems="center" spacing={1}>
        <Grid item xs={6} lg={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableOpenOnEnter
              autoComplete="off"
              fullWidth
              clearable
              size="small"
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Citation From"
              defaultValue={null}
              value={formValue.citationDateFrom || null}
              onChange={(date) => handleReportFormChange(ReportID, 'citationDateFrom', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} lg={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableOpenOnEnter
              autoComplete="off"
              fullWidth
              clearable
              size="small"
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Citation To"
              defaultValue={null}
              value={formValue.citationDateTo || null}
              onChange={(date) => handleReportFormChange(ReportID, 'citationDateTo', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} lg={8}>
          <PersonLookup2
            onPersonChange={(newVal) => {
              handleReportFormChange(ReportID, 'parties', newVal, true);
            }}
            origin="party"
            multiple={true}
            handleError={(err) => console.log({ err })}
          />
          {/* <PersonSearchLite
            placeholder="Person"
            multiple={true}
            partyName={formValue.parties || ''}
            setName={(newVal) => {
              handleReportFormChange(ReportID, 'parties', newVal, true);
            }}
          /> */}
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <RMSDictionary
            options={partyTypes}
            multiple={true}
            value={formValue.partyTypes || []}
            label="Party Type"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'partyTypes', newVal);
            }}
          />
        </Grid>

        <Grid item xs={12} md={8} lg={9}>
          <Autocomplete
            multiple
            size="small"
            autoComplete
            autoSelect={false}
            autoHighlight
            getOptionLabel={(option) => {
              return `${option.Description} | ${option.Code} | ${option.FBICode}`;
            }}
            options={ptsStatueArray}
            key={formValue?.offenses || ''}
            value={formValue?.offenses || []}
            onChange={(event, newValue) => {
              handleReportFormChange(ReportID, 'offenses', newValue);
            }}
            renderInput={(params) => (
              <Grid container xs={12} a className="mb-3 pt-1 d-flex">
                <TextField
                  autoComplete="off"
                  size="small"
                  {...params}
                  label="Search Offense"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <RMSDictionary
            options={'codeSex'}
            value={formValue.sex || ''}
            label="Sex"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'sex', newVal);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <RMSDictionary
            options={'codeRace'}
            value={formValue.race || ''}
            label="Race"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'race', newVal);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RMSDictionary
            options={'codeEthnicity'}
            value={formValue.ethnicity || ''}
            label="Ethnicity"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'ethnicity', newVal);
            }}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <TextField
            id="standard-textarea-address-identifiers-unit"
            autoComplete="hidden"
            size="small"
            label="Plate Number"
            placeholder="Plate Number"
            variant="outlined"
            fullWidth
            name="plateNumber"
            key="plateNumber"
            value={formValue.plateNumber}
            onChange={(ev) => handleReportFormChange(ReportID, 'plateNumber', ev.target.value)}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-jewelry-make"
            options={codeVehicleMakes}
            serviceName="codeVehicleMakes"
            label="Make"
            variant="outlined"
            title="Description"
            formType={'add'}
            stateHolder={demoForm}
            setStateHolder={demoForm}
            setField={(event, value) => {
              handleFormChangeNew(event, value, 'none', 'make', setDemoForm);
              handleReportFormChange(ReportID, 'make', value);
            }}
            stateHolderAttribute="make"
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-make-model"
            options={filteredVehicleModels}
            serviceName="codeVehicleMakesModels"
            label="Model"
            variant="outlined"
            title="ModelCode"
            stateHolder={demoForm}
            setStateHolder={demoForm}
            setField={(event, value) => {
              if (value) {
                handleFormChangeNew(event, value.ModelCode, 'none', 'model', setDemoForm);
                handleReportFormChange(ReportID, 'model', value.ModelCode);
              } else {
                handleFormChangeNew(event, null, 'none', 'model', setDemoForm);
                handleReportFormChange(ReportID, 'model', '');
              }
            }}
            stateHolderAttribute="model"
            hasDifferentPrimaryKey={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CitationReportFilter;
